<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].website.indexImageTitle" class="hero-content">
					<img src="~/assets/images/logo-ocean-house-icon-only-white.png" alt="Ocean House Scheveningen" />
					<h2 class="hero-title">
						{{ defaults[locale].website.indexImageTitle }}
					</h2>
					<h3 class="hero-subtitle">
						{{ defaults[locale].website.indexImageSubtitle }}
					</h3>
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #bgImage>
				<picture>
					<source srcset="/index-intro-logo-bg.png" />
					<source srcset="/index-intro-logo-bg-small.png" media="(max-width: 800px)" />
					<img class="fade-image" src="/index-intro-logo-bg.png" loading="lazy" />
				</picture>
			</template>
		</main-content>

		<section class="booking-benefits">
			<div class="row">
				<div class="columns column4 left bg-image-content">
					<h2>{{ defaults[locale].website.benefitsHeader }}</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].website.bookingBenefits" :key="key">
							<font-awesome-icon icon="fa-light fa-badge-check" size="1x" />{{ benefit.content }}
						</li>
					</ul>
					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
				</div>
			</div>
			<div class="bg-image">
				<picture>
					<source
						v-if="defaults[locale].website.bannerImageWebp"
						:srcset="defaults[locale].website.bannerImageWebp"
						type="image/webp"
					/>
					<source :srcset="defaults[locale].website.bannerImage" />
					<img
						:src="defaults[locale].website.bannerImage"
						:alt="defaults[locale].website.bannerImageAlt"
						loading="lazy"
					/>
				</picture>
			</div>
		</section>

		<promoblocks :header="defaults[locale].website.indexPromotionTitle" :items="promoblocksData" :amount="3" />

		<contentblocks v-if="page && page.contentblocks && page.contentblocks.length" :items="page.contentblocks" />
		<review-slider
			v-if="page && page.showReviewSlider"
			:key="$route.path"
			:header="defaults[locale].website.reviewsHeader"
		/>

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].website.newsletterHeader"
			:content="defaults[locale].website.newsletterContent"
			:button="defaults[locale].website.newsletterButton"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	z-index: 4;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-in 1500ms ease-out;

	img {
		max-width: 129px;
		margin: 0 0 10px;
		transition: all 0.3s ease-in-out;
		animation:
			zoom-in 9000ms ease-in-out forwards,
			fade-in 800ms ease-out;
	}

	h2 {
		font-size: 78px;
		letter-spacing: 2px;
		color: #fff;
		font-weight: 200;
	}

	h3 {
		font-weight: 400;
		font-size: 40px;
		line-height: 100%;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #fff;
	}
}

.booking-benefits {
	position: relative;
	padding: 130px 0;
	background: linear-gradient(180deg, #a79f88 90%, $alternating-background-color 10%);

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			line-height: 44px;
			font-size: 21px;
			vertical-align: middle;

			svg {
				margin: 0 7px 0 0;
				font-size: 20px;
				display: inline-block;
				color: #a79f88;
			}
		}
	}
}

.bg-image {
	position: absolute;
	inset: 65px 0 0 24%;
	z-index: 1;

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

.bg-image-content {
	background: rgba(255 255 255 / 84%);
	backdrop-filter: blur(34px);
	padding: 50px;
	z-index: 3;
}

@media (max-width: 900px) {
	.bg-image-content {
		padding: 40px;
	}
}

@media (max-width: 600px) {
	.bg-image-content {
		padding: 30px;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.08);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 800px) {
	.hero-image .hero-content h2 {
		font-size: 4em;
	}
}

@media (max-width: 600px) {
	.hero-image .hero-content h2 {
		font-size: 3em;
	}
}

@media (max-width: 500px) {
	.hero-image .hero-content h3 {
		font-size: 14px;
	}

	.hero-image .hero-content h2 {
		font-size: 30px;
	}
}
</style>
